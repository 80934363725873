import { Dispatch, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { CaptchaRequest } from './types';

export interface GuardedCaptchaV2Props<Result> {
  onCaptchaAllowed: Dispatch<Result>;
  onCaptchaBlocked: () => void;
  onRequestFailed: Dispatch<Error>;
  reCaptchaKey: string;
  request: CaptchaRequest<Result>;
}

export function GuardedCaptchaV2<Result>({
  onCaptchaAllowed,
  onCaptchaBlocked,
  onRequestFailed,
  reCaptchaKey,
  request,
}: GuardedCaptchaV2Props<Result>) {
  const [token, setToken] = useState<string>();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const onVerify = async (captchaToken: string) => {
    setToken(captchaToken);

    try {
      const response = await request(captchaToken);

      if (response.allowed) {
        onCaptchaAllowed(response.result);
      } else if (response.error) {
        onRequestFailed(response.error);
      } else {
        onCaptchaBlocked();
      }
    } catch (error) {
      onRequestFailed(error as Error);
    }
  };

  useEffect(() => {
    if (!token) {
      reCaptchaRef.current?.execute();
    }
  }, [token]);

  return (
    <ReCAPTCHA
      onChange={(token) => token && onVerify(token)}
      ref={reCaptchaRef}
      sitekey={reCaptchaKey}
      size="invisible"
    />
  );
}
