import { Anchor, Box, GridForm, GridFormField, Text } from '@codecademy/gamut';
import { CaptchaResponse, sendCaptchaGuardedRequest } from '@mono/ui/captcha';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import { ComponentProps, useEffect, useRef, useState } from 'react';
import Cookies from 'universal-cookie';

import { cfAccessHeaders } from '@mono/data/cf-access-headers';
import { LoginOrRegisterProps } from '../LoginOrRegister';
import {
  getAlert,
  UserFormAlertArea,
  UserFormBodyWrapper,
  UserFormFooter,
  UserFormWrapper,
} from '../LoginOrRegister/elements';
import { AlertsState, TrackingData } from '../LoginOrRegister/types';
import { redirectAfterSignup } from '../LoginOrRegister/utils';
import { OauthButtons } from '../OAuthButtons';
import { LoginValues } from './types';

export const forgotPasswordUrl = () => '/secret/new';
interface LoginProps
  extends Pick<LoginOrRegisterProps, 'embedded' | 'variant'> {
  toggleView: () => void;
  trackingData?: TrackingData;
  redirectUrl?: string;
  reminderEmailData?: {
    content_path: string;
    content_title: string;
    content_pro_exclusive: boolean;
    content_is_path: boolean;
  };
  titleAs?: ComponentProps<typeof Text>['as'];
  renderTitle?: (
    ref?: React.RefObject<HTMLElement>,
    showLogin?: boolean
  ) => React.ReactNode;
  renderSubtitle?: (showLogin: boolean) => React.ReactNode;
  buttonText?: string;
  disableFocusOnMount?: boolean;
  onSubmit?: (isLogin: boolean) => void;
}

interface ResponseBody {
  error?: string;
}

interface LoginPost {
  payload: LoginValues;
  captchaToken?: string;
  captchaVersion?: 'v2' | 'v3';
}

export const Login: React.FC<LoginProps> = ({
  buttonText,
  disableFocusOnMount,
  embedded,
  redirectUrl,
  reminderEmailData,
  renderTitle,
  renderSubtitle,
  titleAs = 'h1',
  toggleView,
  trackingData,
  variant = 'floating',
  onSubmit,
}) => {
  const [captcha, setCaptcha] = useState<JSX.Element | null>(null);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState<AlertsState>([]);
  const alertToDisplay = alerts.find((alert) => alert.message);
  const locationType = router.asPath.slice(1); // Removes the preceding '/'
  const titleRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!disableFocusOnMount) titleRef.current?.focus();
  }, [disableFocusOnMount]);

  const loginPost = async ({
    payload,
    captchaToken,
    captchaVersion,
  }: LoginPost): Promise<CaptchaResponse<Response>> => {
    try {
      const cookies = new Cookies();
      const result = await fetch(`${process.env.NEXT_PUBLIC_BASE_PATH}/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': cookies.get('CSRF-TOKEN') as string,
          ...cfAccessHeaders,
        },
        body: JSON.stringify({
          ...payload,
          captcha_token: captchaToken,
          captcha_version: captchaVersion,
          reminder_email: reminderEmailData,
          tracking_data: omit(trackingData, 'content_ids'),
        }),
      });
      const responseBody = (await result.json()) as ResponseBody;
      if (result.ok) {
        return { allowed: true, result };
      }
      return {
        allowed: false,
        error: new Error(responseBody?.error || result.statusText),
      };
    } catch (error) {
      return { allowed: false, error: error as Error };
    }
  };
  const submitLogin = (values: LoginValues) => {
    onSubmit?.(true);
    setAlerts([]);
    setLoading(true);
    const payload = {
      ...values,
      user: {
        login: values.user.login,
        password: values.user.password,
      },
      redirect: '',
    };
    const [captcha, captchaPromise] = sendCaptchaGuardedRequest({
      action: 'login',
      requestV2: (captchaToken) =>
        loginPost({
          payload: {
            ...payload,
          },
          captchaVersion: 'v2',
          captchaToken,
        }),
      requestV3: (captchaToken) =>
        loginPost({
          payload: {
            ...payload,
          },
          captchaVersion: 'v3',
          captchaToken,
        }),
      reCaptchaKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
    });
    setCaptcha(captcha);
    captchaPromise
      .then((res) => {
        if (res.status === 'failed') {
          setAlerts([
            { type: 'error', message: res.error.message || 'reCAPTCHA failed' },
          ]);
          setLoading(false);
        } else if (res.status === 'blocked') {
          setAlerts([{ type: 'error', message: 'Blocked by reCAPTCHA' }]);
          setLoading(false);
        } else if (redirectUrl) {
          const cookies = new Cookies();
          redirectAfterSignup({
            redirectTo: redirectUrl || '',
            authenticationToken: cookies.get('CSRF-TOKEN') as string,
            email: values.user.login,
          });
        } else {
          window.location.reload();
        }
        setCaptcha(null);
      })
      .catch(() => {
        setAlerts([
          {
            type: 'error',
            message:
              'We were unable to complete your request. Please try again later.',
          },
        ]);
        setCaptcha(null);
        setLoading(false);
      });
  };

  const fieldArray: GridFormField[] = [
    {
      type: 'text',
      name: 'user[login]',
      size: 12,
      label: 'Email or username',
      id: 'user_login',
      validation: {
        required: true,
      },
    },
    {
      type: 'password',
      name: 'user[password]',
      size: 12,
      label: 'Password',
      id: 'login__user_password',
      validation: {
        required: true,
      },
    },
    {
      type: 'custom',
      hideLabel: true,
      render: () => (
        <Text color="danger" data-testid="error-text" aria-live="polite">
          {alertToDisplay && getAlert(alertToDisplay)}
        </Text>
      ),
      name: 'errors',
      size: 12,
    },
    {
      type: 'custom',
      hideLabel: true,
      render: () => (
        <Anchor
          lineHeight={'title'}
          href={forgotPasswordUrl()}
          target={
            embedded
              ? '_parent'
              : locationType === 'location/COURSE_CONTENT_ITEM'
              ? '_blank'
              : undefined
          }
          rel="noreferrer"
          variant="standard"
        >
          Forgot password
        </Anchor>
      ),
      name: 'reset_password',
      size: 12,
    },
  ];

  const isFancy = variant === 'fancy';

  return (
    <Box width="100%">
      {alertToDisplay && isFancy && (
        <UserFormAlertArea onClose={() => setAlerts([])}>
          {getAlert(alertToDisplay)}
        </UserFormAlertArea>
      )}
      <UserFormWrapper embedded={embedded} variant={variant}>
        <UserFormBodyWrapper variant={variant}>
          {renderTitle?.(titleRef, true) ?? (
            <Text
              as={titleAs}
              fontSize={{
                _: 26,
                md: 34,
              }}
              ref={titleRef}
              tabIndex={-1}
              variant="title-md"
            >
              {`Log in${isFancy ? ' to Codecademy' : ''}`}
            </Text>
          )}
          {renderSubtitle?.(true)}
          <Box>
            <GridForm
              fields={fieldArray.filter((field) =>
                alerts.length && !isFancy ? true : field.name !== 'errors'
              )}
              onSubmit={submitLogin}
              // we need to adjust the row gap here to fit the relatively long term text.
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              rowGap={12 as any}
              submit={{
                disabled: ({ isSubmitting }) => isSubmitting || loading,
                contents: buttonText ?? 'Log in',
                size: 12,
                position: 'stretch',
              }}
              validation="onChange"
            />
          </Box>
          {captcha}
          <Box>
            <OauthButtons
              isFancy={isFancy}
              trackingData={trackingData}
              urlParams={{
                redirectUrl,
                ...reminderEmailData,
              }}
            />
          </Box>
        </UserFormBodyWrapper>

        <UserFormFooter variant={variant} view="login">
          <Anchor
            variant={isFancy ? 'standard' : 'inline'}
            onClick={toggleView}
          >
            Sign up for free
          </Anchor>
        </UserFormFooter>
      </UserFormWrapper>
    </Box>
  );
};
