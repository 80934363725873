import { Anchor, Text, TextProps } from '@codecademy/gamut';
import React from 'react';

export interface TermsOfServiceProps
  extends Pick<TextProps, 'color' | 'fontSize' | 'lineHeight' | 'mt'> {
  terms?: string;
}

export const TermsOfService: React.FC<TermsOfServiceProps> = ({
  color = 'text',
  fontSize = 14,
  lineHeight = 'base',
  mt = 'initial',
  terms = '/terms',
}) => {
  return (
    <Text
      as="small"
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      mt={mt}
    >
      By signing up, you agree to the Codecademy{' '}
      <Anchor target="_blank" href={terms} variant="inline">
        Terms of Service
      </Anchor>{' '}
      and{' '}
      <Anchor target="_blank" href="/policy" variant="inline">
        Privacy Policy
      </Anchor>
      . This site is protected by reCAPTCHA and the Google{' '}
      <Anchor
        target="_blank"
        href="https://policies.google.com/privacy"
        variant="inline"
      >
        Privacy Policy
      </Anchor>{' '}
      and{' '}
      <Anchor
        target="_blank"
        href="https://policies.google.com/terms"
        variant="inline"
      >
        Terms of Service
      </Anchor>{' '}
      apply.
    </Text>
  );
};
