export enum UserSubmitKey {
  CODING_REMINDERS_COMMS_TIMEZONE = 'coding_reminders_comms_timezone',
  EMAIL = 'email',
  PASSWORD = 'password', // pragma: allowlist secret
  MOBILE = 'mobile',
}

export enum ErrorMessages {
  WEAK = 'This password is too weak.',
  BREACH = 'This password may not be secure. [Learn more.](https://help.codecademy.com/hc/en-us/articles/360051956894-How-does-Codecademy-know-that-my-password-might-be-insecure-)',
  LENGTH = 'Password must include at least 8 characters.',
  VALID_EMAIL = 'Please enter a valid email address.',
  UNIQUE_EMAIL = 'An account with this email already exists.',
  VALID_MOBILE = 'Please enter a valid 10 digit mobile number.',
}
export interface BaseSubmitValues {
  authenticity_token: string;
  redirect: string;
  referring_page: string;
  sweet_container: boolean;
}
export type UserSubmitValues = Record<UserSubmitKey, string>;
export type SubmitValues = UserSubmitValues & BaseSubmitValues;
