import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { type PropsWithChildren, useEffect } from 'react';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

const HiddenBadge = styled.div(
  css({ height: 0, position: 'fixed', visibility: 'hidden', width: 0 })
);

export interface GuardedCaptchaProviderProps extends PropsWithChildren {
  reCaptchaKey?: string;
  hideBadge?: boolean;
}

/**
 * Basic wrapper for GoogleReCaptchaProvider
 */
export function GuardedCaptchaProvider({
  children,
  hideBadge = true,
}: GuardedCaptchaProviderProps) {
  const badgeId = 'googleBadge';
  const scriptId = 'google-recaptcha-v3';

  const publicRecaptchaSiteKeyV3 =
    process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_V3;

  if (publicRecaptchaSiteKeyV3 === undefined) {
    throw new Error('NEXT_PUBLIC_RECAPTCHA_SITE_KEY_V3 is undefined');
  }

  const alreadyProvided = !!useGoogleReCaptcha().container;

  useEffect(() => {
    if (!alreadyProvided) {
      // Workaround for double initialization issue:
      // Temporarily remove script id so that it is not removed
      // by cleanup and then added again in next render.
      const script = document.getElementById(scriptId);
      if (script) {
        script.removeAttribute('id');
        return () => {
          script.setAttribute('id', scriptId);
        };
      }
    }
    return () => null; // empty cleanup so that all code paths return a value
  }, [alreadyProvided]);

  if (alreadyProvided) {
    return children; // no need to provide again
  }

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={publicRecaptchaSiteKeyV3}
        container={
          hideBadge
            ? {
                element: badgeId,
                parameters: {
                  badge: 'inline',
                },
              }
            : undefined
        }
        scriptProps={{
          id: scriptId,
          ...(hideBadge ? {} : { async: true, appendTo: 'body' }),
        }}
      >
        {children}
      </GoogleReCaptchaProvider>
      {hideBadge && <HiddenBadge id={badgeId} />}
    </>
  );
}
