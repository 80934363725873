import { FlexBox, Text } from '@codecademy/gamut';
import { CheckerDense } from '@codecademy/gamut-patterns';

import { OauthButtonsProps } from '.';
export type OAuthTitleProps = Pick<OauthButtonsProps, 'isFancy' | 'signUp'>;

export const getOAuthTitleCopy = ({ isFancy, signUp }: OauthButtonsProps) => {
  const endOfCopy = isFancy ? 'with' : 'using:';

  return signUp ? `Or sign up ${endOfCopy}` : `Or log in ${endOfCopy}`;
};

export const OAuthHeader: React.FC<OAuthTitleProps> = ({
  isFancy,
  signUp,
}: OauthButtonsProps) => {
  const oAuthTitleCopy = getOAuthTitleCopy({ isFancy, signUp });

  return (
    <FlexBox
      alignItems="center"
      justifyContent={isFancy ? 'space-around' : 'left'}
      width="100%"
    >
      {isFancy && <CheckerDense height="4px" />}
      <Text
        as="h2"
        fontSize={16}
        my={12}
        pl={isFancy ? 12 : 0}
        pr={12}
        whiteSpace={'nowrap'}
      >
        {oAuthTitleCopy}
      </Text>
      {isFancy && <CheckerDense height="4px" />}
    </FlexBox>
  );
};
