import { Anchor, Text } from '@codecademy/gamut';
import { Avatar } from '@mono/brand';
import { LoggedInUser } from '@mono/data/user';
import React from 'react';

import { LoginOrRegisterProps } from '../LoginOrRegister';
import {
  UserFormBodyWrapper,
  UserFormFooter,
  UserFormWrapper,
} from '../LoginOrRegister/elements';

interface LoggedInProps
  extends Pick<LoginOrRegisterProps, 'variant' | 'embedded'> {
  user: LoggedInUser;
}

export const LoggedIn: React.FC<LoggedInProps> = ({
  embedded,
  variant,
  user,
}) => {
  const { email, profile_image_url, username } = user;

  return (
    <UserFormWrapper embedded={embedded} variant={variant}>
      <UserFormBodyWrapper variant={variant}>
        <Avatar
          alt={`${username}'s avatar`}
          disableDropshadow
          src={profile_image_url}
          size={64}
        />
        <Text lineHeight="base" mb={4} mt={24} variant="title-md" width="100%">
          Continue as {username}?
        </Text>
        <Text variant="p-large">
          You are currently logged in as {email}. Not you?{' '}
          <Anchor href="/sign_out" target="_parent">
            Log out
          </Anchor>
        </Text>
      </UserFormBodyWrapper>
      <UserFormFooter variant={variant} view="loggedin" />
    </UserFormWrapper>
  );
};
