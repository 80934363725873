import { GuardedCaptcha } from './GuardedCaptcha';
import { CaptchaGuardedRequest, CaptchaGuardedResult } from './types';

/**
 * Small wrapper around GuardedCaptcha that turns it into an imperative function.
 * @see GuardedCaptcha
 */
export function sendCaptchaGuardedRequest<Result>(
  props: CaptchaGuardedRequest<Result>
) {
  let resolve!: (
    value:
      | CaptchaGuardedResult<Result>
      | PromiseLike<CaptchaGuardedResult<Result>>
  ) => void;

  const promise = new Promise<CaptchaGuardedResult<Result>>((innerResolve) => {
    // Yes, the 'executor' passed to a Promise is executed immediately.
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise#Parameters
    resolve = innerResolve;
  });

  return [<GuardedCaptcha {...props} resolve={resolve} />, promise] as const;
}
