import {
  Alert,
  AlertProps,
  Anchor,
  Box,
  Card,
  FillButton,
  FlexBox,
  FloatingCard,
  Text,
} from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { AlertArea } from '@mono/brand';

import { EmbeddediFrame, LogInOrRegisterVariant, ServerAlert } from './types';
import { LoginOrRegisterCopyProps, getUserFormFooterCopy } from './utils';

import { ReactFCWithChildren } from '@mono/util/typings';

const xSpacing = 32;

export interface UserFormWrapperProps extends EmbeddediFrame {
  variant?: LogInOrRegisterVariant;
  minHeight?: string;
}

export const UserFormWrapper: ReactFCWithChildren<UserFormWrapperProps> = ({
  children,
  embedded,
  variant,
  minHeight,
}) => {
  if (variant === 'flat')
    return (
      <Card p={16} width={585} maxWidth="100%" border="none">
        {children}
      </Card>
    );

  if (variant === 'fancy') {
    return (
      <FlexBox
        bg="background"
        border={1}
        borderColor={embedded ? 'text' : 'background'}
        borderRadius="sm"
        justifyContent="space-between"
        flexDirection="column"
        minHeight={minHeight ? minHeight : '594px'}
        width="100%"
        pt={{ _: embedded ? 32 : 8, md: 32 }}
      >
        {children}
      </FlexBox>
    );
  }

  if (variant === 'basic') {
    return children;
  }

  return (
    <FloatingCard p={16} width={585} maxWidth="100%">
      {children}
    </FloatingCard>
  );
};

export const UserFormBodyWrapper: ReactFCWithChildren<UserFormWrapperProps> = ({
  children,
  variant,
}) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (variant !== 'fancy') return <>{children}</>;
  return (
    <Box px={xSpacing} height="100%">
      {children}
    </Box>
  );
};

export const UserFormFooter: ReactFCWithChildren<LoginOrRegisterCopyProps> = ({
  children,
  variant,
  view,
}) => {
  const copy = getUserFormFooterCopy({ variant, view });

  const isFancy = variant === 'fancy';

  if (isFancy && view === 'loggedin') {
    return (
      <FillButton
        href="/learn"
        mb={32}
        target="_parent"
        mx={xSpacing}
        width="calc(100% - 64px)"
      >
        Continue
      </FillButton>
    );
  }

  if (!isFancy)
    return (
      <Box mt={16}>
        <Text fontSize={14}>
          {copy} {children}
        </Text>
      </Box>
    );

  return (
    <FlexBox bg="navy-100" center py={12}>
      <Text fontSize={16}>
        {copy} {children}
      </Text>
    </FlexBox>
  );
};

const IFrameAlertArea = styled(AlertArea)(css({ top: 0, p: 16 }));

export const UserFormAlertArea: ReactFCWithChildren<
  Pick<AlertProps, 'onClose'>
> = ({ onClose, children }) => (
  <IFrameAlertArea>
    <Alert placement="floating" type="error" onClose={onClose}>
      {children}
    </Alert>
  </IFrameAlertArea>
);

export const getAlert = ({ message, link }: ServerAlert) =>
  link ? <Anchor href={link}>{message}</Anchor> : message;
