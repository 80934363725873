import { cfAccessHeaders } from '@mono/data/cf-access-headers';
import { CaptchaResponse } from '@mono/ui/captcha';
import Cookies from 'universal-cookie';
import { SubmitValues } from './types';

interface RegisterPost {
  payload: Omit<SubmitValues, 'email' | 'password' | 'mobile'>;
  captchaToken?: string;
  captchaVersion?: 'v2' | 'v3';
}

export const registerAccount = async ({
  payload,
  captchaToken,
  captchaVersion,
}: RegisterPost): Promise<CaptchaResponse<Response>> => {
  try {
    const cookies = new Cookies();
    const result = await fetch(
      `${process.env.NEXT_PUBLIC_BASE_PATH}/register`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': cookies.get('CSRF-TOKEN') as string,
          ...cfAccessHeaders,
        },
        body: JSON.stringify({
          ...payload,
          captcha_token: captchaToken,
          captcha_version: captchaVersion,
        }),
      }
    );
    if (result.ok) {
      return { allowed: true, result };
    }
    return {
      allowed: false,
      error: new Error(result.statusText),
    };
  } catch (error) {
    return { allowed: false, error: error as Error };
  }
};
