import Uri from 'jsuri';
import { Dispatch, SetStateAction } from 'react';

import { LogInOrRegisterVariant } from './types';

export interface SuccessCallbackProp {
  redirectTo: string;
  authenticationToken: string;
  email: string;
}

interface SetFullPathToStateProps {
  fullpath: string | undefined;
  setFullpath: Dispatch<SetStateAction<string | undefined>>;
}
export const setFullPathToState = ({
  fullpath,
  setFullpath,
}: SetFullPathToStateProps) => {
  if (
    fullpath === undefined &&
    window.top &&
    window.location !== window.parent.location
  ) {
    /* checks if the redirect is within an iframe and if the frame is loaded to properly recieve the message to get the fullpath from its parent.
      this accompanying script needs to be run in the parent:
        <script>
            addEventListener('message', (event) => {
              const { origin, data } = event;
              if (origin.endsWith('codecademy.com') && data?.getFullPath) {
                const iframe = document.querySelector('#user-form');
                const fullpath = window.top.location.href;
                iframe.contentWindow.postMessage({ fullpath }, '*');
              }
            });
        </script>

        this can be combined into a single EventListener with the other script below.
      */

    window.top.postMessage({ getFullPath: true }, '*');
    window.addEventListener('message', (event) => {
      const { data } = event;
      if (
        window.top &&
        window.location !== window.parent.location &&
        data?.fullpath
      ) {
        const { fullpath } = data;
        setFullpath(fullpath);
      }
    });
  }
};

export const redirectAfterSignup = ({ redirectTo }: SuccessCallbackProp) => {
  const url = new Uri(redirectTo).toString();
  if (window.top && window.location !== window.parent.location) {
    /* checks if the redirect is within an iframe - the accompanying frame needs to have this script to properly redirect:
        <script>
          addEventListener('message', event => {
            const { origin, data } = event;
            if (origin.endsWith('codecademy.com') && data?.codecademyPath) {
              window.top.location.assign(origin + data.codecademyPath);
            }});
        </script>
      */

    window.top.postMessage({ codecademyPath: url }, '*');
  } else {
    window.location.assign(url);
  }
};

export interface LoginOrRegisterCopyProps {
  variant?: LogInOrRegisterVariant;
  view: 'login' | 'register' | 'loggedin';
}

export const getUserFormFooterCopy = ({
  variant,
  view,
}: LoginOrRegisterCopyProps) => {
  const isFancy = variant === 'fancy';
  if (view === 'register') {
    return isFancy ? 'Have an account?' : 'Already have an account?';
  }

  return isFancy ? 'No account yet?' : "Don't have an account?";
};
