import { SVGProps } from 'react';

export const GithubLogo: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props}>
    <title>Github</title>
    <path
      d="M9 0C4.027 0 0 4.03 0 9a9 9 0 0 0 6.154 8.539c.45.084.615-.194.615-.433 0-.214-.008-.78-.011-1.53-2.504.543-3.032-1.208-3.032-1.208-.41-1.039-1.001-1.316-1.001-1.316-.816-.558.063-.546.063-.546.903.063 1.378.926 1.378.926.803 1.376 2.107.98 2.621.749.081-.582.313-.98.57-1.204-1.999-.225-4.099-1-4.099-4.447 0-.982.349-1.786.926-2.416-.101-.227-.405-1.141.079-2.381 0 0 .753-.242 2.475.922a8.66 8.66 0 0 1 2.25-.304 8.66 8.66 0 0 1 2.25.304c1.71-1.164 2.463-.922 2.463-.922.484 1.24.18 2.154.09 2.381.574.63.923 1.434.923 2.416 0 3.457-2.104 4.219-4.106 4.44.315.27.607.821.607 1.665 0 1.204-.011 2.171-.011 2.464 0 .236.157.517.619.427A8.968 8.968 0 0 0 18 9a9 9 0 0 0-9-9"
      fillRule="evenodd"
    />
  </svg>
);
