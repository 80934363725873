import { SVGProps } from 'react';

export const LinkedInLogo: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} viewBox="0 0 980 980" xmlns="http://www.w3.org/2000/svg">
    <title>LinkedIn</title>
    <path
      d="M882 0H98C44.1 0 0 44.1 0 98v784c0 53.9 44.1 98 98 98h784c53.9 0 98-44.1 98-98V98c0-53.9-44.1-98-98-98zM294 833H147V392h147v441zm-73.5-524.3c-49 0-88.2-39.2-88.2-88.2s39.2-88.2 88.2-88.2 88.2 39.2 88.2 88.2-39.2 88.2-88.2 88.2zM833 833H686V573.3c0-39.2-34.3-73.5-73.5-73.5S539 534.1 539 573.3V833H392V392h147v58.8c24.5-39.2 78.4-68.6 122.5-68.6 93.1 0 171.5 78.4 171.5 171.5V833z"
      fill="#0E76A8"
      fillRule="nonzero"
    />
  </svg>
);
